import { myPalette } from '~/shared/lib/theme'
import {
  AccidentRegistrationTypeEnum,
  DiskTypeEnum,
  DocumentsTypeEnum,
  IcReviewStatusEnum,
  IncidentResponsibleEnum,
  InsurancePolicyStatusEnum,
  RoadAccidentsStatusEnum,
  RepairOrderPaymentStatusEnum,
  RepairOrderStatusEnum,
  RubberTypeEnum,
  WheelCodeEnum,
} from './enums'

export const AUTH_TOKEN_STORAGE_KEY = 'auth_token'
export const AUTH_EMAIL_STORAGE_KEY = 'auth_email'
export const AUTH_CURRENT_USER_ID_STORAGE_KEY = 'auth_current_user_id'
export const USER_PERMISSIONS_STORAGE_KEY = 'user_permissions'

export const MIN_COLUMN_WIDTH = 90

export type Option = { id: string | number; label: string | number }
export type OptionEnum<T> = { id: T; label: string }

export const documentsCodeEnumLabels: Record<DocumentsTypeEnum, string> = {
  [DocumentsTypeEnum.OSAGO]: 'Осаго',
  [DocumentsTypeEnum.STS]: 'СТС',
  [DocumentsTypeEnum.DC]: 'Диагностическая карта',
  [DocumentsTypeEnum.LICENSE]: 'Лицензия',
}
export const documentsCodeEnumOptions: OptionEnum<DocumentsTypeEnum>[] =
  Object.keys(DocumentsTypeEnum).map((value) => {
    const label =
      documentsCodeEnumLabels[value as keyof typeof DocumentsTypeEnum]
    return {
      label,
      id: value as DocumentsTypeEnum,
    }
  })

export const diskTypeEnumLabels: Record<DiskTypeEnum, string> = {
  [DiskTypeEnum.STAMPED]: 'Штампованный',
  [DiskTypeEnum.STAMPED_WITH_HOOD]: 'Штампованный с колпаком',
  [DiskTypeEnum.CAST]: 'Литые',
}
export const diskTypeEnumOptions: OptionEnum<DiskTypeEnum>[] = Object.keys(
  DiskTypeEnum,
).map((value) => {
  const label = diskTypeEnumLabels[value as keyof typeof DiskTypeEnum]
  return {
    label,
    id: value as DiskTypeEnum,
  }
})

export const rubberTypeEnumLabels: Record<RubberTypeEnum, string> = {
  [RubberTypeEnum.WINTER]: 'Зимняя',
  [RubberTypeEnum.SUMMER]: 'Летняя',
}
export const rubberTypeEnumOptions: OptionEnum<RubberTypeEnum>[] = Object.keys(
  RubberTypeEnum,
).map((value) => {
  const label = rubberTypeEnumLabels[value as keyof typeof RubberTypeEnum]
  return {
    label,
    id: value as RubberTypeEnum,
  }
})

export const wheelCodeEnumLabels: Record<WheelCodeEnum, string> = {
  [WheelCodeEnum.FRONT_LEFT]: 'Переднее левое колесо',
  [WheelCodeEnum.FRONT_RIGHT]: 'Переднее правое колесо',
  [WheelCodeEnum.REAR_LEFT]: 'Заднее левое колесо',
  [WheelCodeEnum.REAR_RIGHT]: 'Заднее правое колесо',
}
export const wheelCodeEnumOptions: OptionEnum<WheelCodeEnum>[] = Object.keys(
  WheelCodeEnum,
).map((value) => {
  const label = wheelCodeEnumLabels[value as keyof typeof WheelCodeEnum]
  return {
    label,
    id: value as WheelCodeEnum,
  }
})

export const incidentResponsibleEnumLabels: Record<
  IncidentResponsibleEnum,
  string
> = {
  [IncidentResponsibleEnum.DRIVER]: 'Водитель',
  [IncidentResponsibleEnum.NOT_DRIVER]: 'Не водитель',
  [IncidentResponsibleEnum.UNKNOWN]: 'Не установлен',
}
export const incidentResponsibleEnumOptions: OptionEnum<IncidentResponsibleEnum>[] =
  Object.keys(IncidentResponsibleEnum).map((value) => {
    const label =
      incidentResponsibleEnumLabels[
        value as keyof typeof IncidentResponsibleEnum
      ]
    return {
      label,
      id: value as IncidentResponsibleEnum,
    }
  })

export const repairOrderStatusEnumLabels: Record<
  RepairOrderStatusEnum,
  string
> = {
  [RepairOrderStatusEnum.OPENED]: 'Открыт',
  [RepairOrderStatusEnum.AWAITING_REPAIR]: 'Ожидает ремонта',
  [RepairOrderStatusEnum.AWAITING_SPARE_PARTS]: 'Ожидает запчастей',
  [RepairOrderStatusEnum.AT_WORK]: 'В работе',
  [RepairOrderStatusEnum.COMPLETED]: 'Завершён',
}
export const repairOrderStatusEnumOptions: OptionEnum<RepairOrderStatusEnum>[] =
  Object.keys(RepairOrderStatusEnum).map((value) => {
    const label =
      repairOrderStatusEnumLabels[value as keyof typeof RepairOrderStatusEnum]
    return {
      label,
      id: value as RepairOrderStatusEnum,
    }
  })

export const repairOrderPaymentStatusEnumLabels: Record<
  RepairOrderPaymentStatusEnum,
  string
> = {
  [RepairOrderPaymentStatusEnum.PAID]: 'Оплачен',
  [RepairOrderPaymentStatusEnum.NOT_PAID]: 'Не оплачен',
}
export const repairOrderPaymentStatusEnumOptions: OptionEnum<RepairOrderPaymentStatusEnum>[] =
  Object.keys(RepairOrderPaymentStatusEnum).map((value) => {
    const label =
      repairOrderPaymentStatusEnumLabels[
        value as keyof typeof RepairOrderPaymentStatusEnum
      ]
    return {
      label,
      id: value as RepairOrderPaymentStatusEnum,
    }
  })

export const roadAccidentsEnumLabels: Record<RoadAccidentsStatusEnum, string> =
  {
    [RoadAccidentsStatusEnum.OPENED]: 'Открыто',
    [RoadAccidentsStatusEnum.CLOSED]: 'Закрыто',
  }
export const roadAccidentsEnumOptions: OptionEnum<RoadAccidentsStatusEnum>[] =
  Object.keys(RoadAccidentsStatusEnum).map((value) => {
    const label =
      roadAccidentsEnumLabels[value as keyof typeof RoadAccidentsStatusEnum]
    return {
      label,
      id: value as RoadAccidentsStatusEnum,
    }
  })

export const accidentRegistrationTypeEnumLabels: Record<
  AccidentRegistrationTypeEnum,
  string
> = {
  [AccidentRegistrationTypeEnum.EUROPROTOCOL]: 'Европротокол',
  [AccidentRegistrationTypeEnum.GIBDD]: 'ГИБДД',
}
export const accidentRegistrationTypeEnumOptions: OptionEnum<AccidentRegistrationTypeEnum>[] =
  Object.keys(AccidentRegistrationTypeEnum).map((value) => {
    const label =
      accidentRegistrationTypeEnumLabels[
        value as keyof typeof AccidentRegistrationTypeEnum
      ]
    return {
      label,
      id: value as AccidentRegistrationTypeEnum,
    }
  })

export const insurancePolicyStatusEnumLabels: Record<
  InsurancePolicyStatusEnum,
  string
> = {
  [InsurancePolicyStatusEnum.VALID]: 'Действителен',
  [InsurancePolicyStatusEnum.NOT_VALID]: 'Не действителен',
}
export const insurancePolicyStatusEnumOptions: OptionEnum<InsurancePolicyStatusEnum>[] =
  Object.keys(InsurancePolicyStatusEnum).map((value) => {
    const label =
      insurancePolicyStatusEnumLabels[
        value as keyof typeof InsurancePolicyStatusEnum
      ]
    return {
      label,
      id: value as InsurancePolicyStatusEnum,
    }
  })

export const icReviewStatusEnumLabels: Record<IcReviewStatusEnum, string> = {
  [IcReviewStatusEnum.DOCUMENTS_SUBMITTED]: 'Поданы документы',
  [IcReviewStatusEnum.DOCUMENTS_READY]: 'Документы готовы',
  [IcReviewStatusEnum.INSPECTION_SCHEDULED]: 'Назначен осмотр',
  [IcReviewStatusEnum.REPAIR_REFERRAL_ISSUED]: 'Выдано направление на ремонт',
  [IcReviewStatusEnum.PAYMENT_WAITING]: 'Ожидаем выплаты',
  [IcReviewStatusEnum.REIMBURSED]: 'Возмещено',
  [IcReviewStatusEnum.REFUSAL]: 'Отказ',
  [IcReviewStatusEnum.COURT_PROCEEDINGS]: 'Судебное разбирательство',
}
export const icReviewStatusEnumOptions: OptionEnum<IcReviewStatusEnum>[] =
  Object.keys(IcReviewStatusEnum).map((value) => {
    const label =
      icReviewStatusEnumLabels[value as keyof typeof IcReviewStatusEnum]
    return {
      label,
      id: value as IcReviewStatusEnum,
    }
  })

export const STATUS_VARIANT = {
  green: {
    backgroundColor: myPalette.green['100'],
    color: myPalette.green['600'],
  },
  outlinedGreen: {
    backgroundColor: myPalette.white.main,
    color: myPalette.green['600'],
    border: `1px solid ${myPalette.green['100']}`,
  },
  red: {
    backgroundColor: myPalette.red['100'],
    color: myPalette.red['600'],
  },
  outlinedRed: {
    backgroundColor: myPalette.white.main,
    color: myPalette.red['600'],
    border: `1px solid ${myPalette.red['600']}`,
  },
  gray: {
    backgroundColor: myPalette.gray['100'],
    color: myPalette.gray['700'],
  },
  yellow: {
    backgroundColor: myPalette.yellow['100'],
    color: myPalette.yellow['600'],
  },
  brand: {
    backgroundColor: myPalette.brand['100'],
    color: myPalette.brand['700'],
  },
  blue: {
    backgroundColor: myPalette.blue['100'],
    color: myPalette.blue['600'],
  },
  purple: {
    backgroundColor: myPalette.purple['100'],
    color: myPalette.purple['600'],
  },
}
